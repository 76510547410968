<template>
  <div class="coupon-details">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/market/coupon' }">优惠券模板</el-breadcrumb-item>
        <el-breadcrumb-item>{{breadcrumb}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="couponForm" :rules="rules" ref="rulForm" class="demo-ruleForm">
        <div class="form-top">
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="券名称：" prop="name">
                <el-input class="form-input" size="small" v-model="couponForm.name"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="类型：" prop="type">
                <el-select class="form-input" size="small" v-model="couponForm.type" placeholder="请选择类型" :disabled="disabled">
                  <el-option v-for="item in discount" :key="item.shop_discount_type_id" :label="item.type_name" :value="item.shop_discount_type_id"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="优惠：" prop="money">
                <el-input class="form-input" size="small" v-model="couponForm.money"></el-input>
                <span class="ml">{{type}}</span>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="满减条件：" prop="reduction">
                <el-input class="form-input" size="small" v-model="couponForm.reduction" :disabled="!is_reduction"></el-input>
                <span class="ml">元</span>
                <el-switch class="ml" v-model="is_reduction"></el-switch>
              </el-form-item>
            </div>
          </div>
          <span class="text">（备注：普通券填入数字直接抵扣现金，折扣券是几折就填对应数字。）</span>
        </div>
        <div class="form-center">
          <el-form-item label="有效期：" required prop="start_time">
            <el-date-picker type="date" placeholder="开始日期" v-model="couponForm.start_time" size="small" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
            <span class="line">-</span>
            <el-date-picker type="date" placeholder="结束日期" v-model="couponForm.end_time" size="small" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="发放方式：" prop="issuing_type">
            <el-radio-group v-model="couponForm.issuing_type">
              <el-radio label="1">指定发券</el-radio>
              <el-radio label="2">全量发放</el-radio>
              <el-button type="warning" size="mini" @click="gotoPopup">选择</el-button>
            </el-radio-group>
            <span class="ml">{{pitch_on}}</span>
          </el-form-item>
          <el-form-item label="使用范围：" prop="range_type">
            <el-select size="small" v-model="couponForm.range_type" placeholder="请选择使用范围">
              <el-option label="商品" value="1"></el-option>
              <el-option label="校区" value="2"></el-option>
              <el-option label="全平台" value="3"></el-option>
            </el-select>
            <el-button type="warning" size="mini" @click="gotoRange" class="ml">选择</el-button>
            <span class="ml">{{pitch_range_on}}</span>
          </el-form-item>
          <el-form-item label="券介绍：">
            <el-input type="textarea" class="form-input" v-model="couponForm.desc"></el-input>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <el-dialog :visible.sync="dialogCode" :before-close="close">
      <div v-if="couponForm.range_type === '1' && windowsB === true">
        <el-table :data="goodsData" @selection-change="handleChangeGoods">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="name" label="商品名称"></el-table-column>
          <el-table-column property="goods_price" label="商品价格"></el-table-column>
          <el-table-column property="chain_name" label="所属机构"></el-table-column>
        </el-table>
        <el-button type="primary" class="mg-top" @click="submitReleased">确定</el-button>
      </div>
      <div v-if="couponForm.range_type === '2' && windowsB === true">
        <el-table :data="chainData" @selection-change="handleChangeChain">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="chain_name" label="机构名称"></el-table-column>
          <el-table-column property="created" label="创建时间"></el-table-column>
          <el-table-column property="under_chain_name" label="所属机构"></el-table-column>
        </el-table>
        <el-button type="primary" class="mg-top" @click="submitReleased">确定</el-button>
      </div>
      <div v-if="couponForm.issuing_type === '1' && windowsA === true">
        <el-table :data="gridData" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="userName" label="客户姓名"></el-table-column>
          <el-table-column property="telNumber" label="客户手机号"></el-table-column>
          <el-table-column property="address" label="客户地址"></el-table-column>
        </el-table>
        <el-button type="primary" class="mg-top" @click="submitReleased">确定</el-button>
      </div>
      <div v-if="couponForm.issuing_type === '2' && windowsA === true" class="released">
        <span>发放数量</span>
        <el-input class="form-input" v-model="couponForm.released" placeholder="输入发放数量"></el-input>
        <el-button type="primary" @click="submitReleased">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getUser, shopGoodsList, shopChainlist, getDiscount, saveDiscount, getDiscountDetails } from '../../../request/api'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      breadcrumb: '新增优惠券',
      couponForm: {
        name: '',
        type: '',
        money: '',
        reduction: '',
        start_time: '',
        end_time: '',
        desc: '',
        // 发券类型
        issuing_type: '',
        // 发券数量
        released: '',
        // 选中发券客户
        multipleSelection: [],
        // 使用范围
        range_type: '',
        // 选中商品
        goodsArr: [],
        // 选中机构
        chainArr: [],
        shop_discount_id: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' }
        ],
        start_time: [
          { required: true, message: '请选择优惠券的开始日期和结束日期', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择优惠券类型', trigger: 'change' }
        ],
        range_type: [
          { required: true, message: '请选择使用范围', trigger: 'change' }
        ],
        money: [
          { required: true, message: '请输入优惠金额', trigger: 'blur' }
        ],
        issuing_type: [
          { required: true, message: '请选择发放方式', trigger: 'change' }
        ]
      },
      disabled: false,
      discount: [],
      type: '',
      is_reduction: false,
      pitch_on: '未选择',
      pitch_range_on: '未选择',
      dialogCode: false,
      // 要发券用户
      gridData: [],
      // 商品列表
      goodsData: [],
      // 校区列表
      chainData: [],
      windowsA: false,
      windowsB: false,
      query_info: {
        chain_id: '',
        limit: 999,
        page: 1
      }
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    async submitForm () {
      this.$refs.rulForm.validate(valid => {
        if (!valid) {
          console.log('error submit!')
          return false
        }
      })
      if (
        this.couponForm.name === '' || 
        this.couponForm.start_time === null || 
        this.couponForm.end_time === null || 
        this.couponForm.type === '' || 
        this.couponForm.range_type === '' || 
        this.couponForm.money === '' || 
        this.couponForm.issuing_type === ''
      ) {
        this.$message.warning('请完善字段信息')
        return
      }

      const res = await saveDiscount(this.couponForm)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('需发布后才能生效')
      this.$router.push({ path: '/market/coupon' })
    },
    resetForm () {
      this.$router.back()
    },
    async getGoodsList () {
      const res = await shopGoodsList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, '')
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.goodsData = res.data.data.goodsList.map(element => {
        return {
          'shop_goods_id': element.shop_goods_id,
          'name': element.name,
          'goods_price': element.goods_price,
          'chain_name': element.chain_name
        }
      })
    },
    async getChainlist () {
      const res = await shopChainlist(this.query_info.chain_id, this.query_info.limit, this.query_info.page, '')
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.chainData = res.data.data.chainList.map(element => {
        return {
          'chain_name': element.chain_name,
          'created': element.created,
          'under_chain_name': element.under_chain_name,
          'shop_chain_id': element.shop_chain_id
        }
      })
    },
    async getDiscountList () {
      const res = await getDiscount()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.discount = res.data.data
    },
    gotoPopup () {
      if (this.couponForm.issuing_type !== '') {

        if (this.couponForm.issuing_type === '1') {
          this.getUserList()
        }

        this.couponForm.released = ''
        this.couponForm.multipleSelection = []

        this.windowsA = true
        this.windowsB = false
        this.dialogCode = true
        this.pitch_on = '已选择'
      }
    },
    gotoRange () {
      if (this.couponForm.range_type !== '') {

        if (this.couponForm.range_type === '1') {
          this.getGoodsList()
          this.windowsB = true
          this.windowsA = false
          this.dialogCode = true
        }
        if (this.couponForm.range_type === '2') {
          this.getChainlist()
          this.windowsB = true
          this.windowsA = false
          this.dialogCode = true
        }
        this.couponForm.goodsArr = []
        this.couponForm.chainArr = []
        this.pitch_range_on = '已选择'
      }
    },
    // 关闭弹窗调用
    close () {
      if (this.couponForm.released === '' && this.couponForm.multipleSelection.length === 0) {
        this.pitch_on = '未选择'
      }
      if (this.couponForm.goodsArr.length === 0 && this.couponForm.chainArr.length === 0 && this.couponForm.range_type !== '3') {
        this.pitch_range_on = '未选择'
      }
      this.dialogCode = false
    },
    submitReleased () {
      if (this.couponForm.released === '' && this.couponForm.multipleSelection.length === 0) {
        this.pitch_on = '未选择'
      }
      if (this.couponForm.goodsArr.length === 0 && this.couponForm.chainArr.length === 0 && this.couponForm.range_type !== '3') {
        this.pitch_range_on = '未选择'
      }
      this.dialogCode = false
    },
    // 改变选中触发该函数
    handleSelectionChange (val) {
      this.couponForm.multipleSelection = val.map(obj => {
        return obj.shop_member_id
      })
    },
    handleChangeGoods (val) {
      this.couponForm.goodsArr = val.map(obj => {
        return obj.shop_goods_id
      })
    },
    handleChangeChain (val) {
      this.couponForm.chainArr = val.map(obj => {
        return obj.shop_chain_id
      })
    },
    // 获取发券用户列表
    async getUserList () {
      const res = await getUser()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.gridData = res.data.data
    },
    // 初始化数据
    async init () {
      if (this.couponForm.shop_discount_id !== undefined) {
        this.breadcrumb = '编辑优惠券'
        const res = await getDiscountDetails(this.couponForm.shop_discount_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        let resData = res.data.data
        this.couponForm.name = resData.name
        this.couponForm.type = resData.type
        this.couponForm.money = resData.money
        this.couponForm.reduction = resData.reduction
        if (resData.reduction !== '') {
          this.is_reduction = true
        }
        this.couponForm.start_time = resData.start_time
        this.couponForm.end_time = resData.end_time
        this.couponForm.desc = resData.desc

        this.couponForm.issuing_type = resData.issuing_type.toString()
        this.couponForm.released = resData.released
        this.couponForm.range_type = resData.range_type.toString()
        this.couponForm.multipleSelection = resData.multipleSelection
        this.pitch_on = '已选择'
        this.couponForm.goodsArr = resData.goodsArr
        this.couponForm.chainArr = resData.chainArr
        this.pitch_range_on = '已选择'
      }
    }
  },
  watch: {
    'couponForm.type': function (newval) {
      switch (newval) {
        case 1:
          this.type = '元'
          break
        case 2:
          this.type = '折'
          break
      }
    }
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
    this.couponForm.shop_discount_id = this.$route.query.shop_discount_id
  },
  mounted () {
    this.getDiscountList()
    this.init()
  }
}
</script>

<style lang="less" scoped>
.coupon-details {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .mg-top {
    margin-top: 10px;
  }
  .released {
    display: flex;
    align-items: center;
    .form-input {
      width: 240px;
      margin: 0 10px;
    }
  }

  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-top {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-compo {
          display: flex;
          .form-compo-div {
            width: 306px;
            .form-input {
              width: 150px;
            }
            .ml {
              margin-left: 10px;
            }
          }
        }
        .text {
          font-size: 12px;
          font-weight: 400;
          color: #858585;
        }
      }
      .form-center {
        background: #fff;
        margin-top: 10px;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-input {
          width: 480px;
        }
        .line {
          margin: 0px 10px;
        }
        .ml {
          margin-left: 10px;
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 10px 0px;
      }
    }
  }
}
</style>